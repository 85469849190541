<template>
	<div class="root">
		<div :style="biStyle" class="head">
			<el-card class="top">
				<h3>
					{{ courseInfo.courseName }}
					<!-- <span v-for="(val, j) in courseInfo.courseLabel" :key="j">{{val}}</span> -->
				</h3>
				<div class="top-content">
					<div class="left">
						<div style="display: flex; margin-bottom: 20px">
							<el-image
								style="
                  width: 400px;
                  height: 200px;
                  vertical-align: top;
                  margin-right: 30px;
                "
								:src="courseInfo.coursePicture"
								fit="cover"
							></el-image>
							<div style="flex: 1; line-height: 2em">
								课程简介：
								<p
									style="
                    text-indent: 2em;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                  "
								>
									{{ courseInfo.courseIntroduction }}
								</p>
							</div>
						</div>
						<p v-if="courseInfo.courseLimitNumber">
							<i class="el-icon-s-custom"> </i> 课程限制人数：{{
								courseInfo.courseLimitNumber
							}}人
						</p>
						<p>
							<i class="el-icon-office-building"> </i>
							课程场景：{{
								courseSite.siteName || courseInfo.dept
							}}
						</p>
						<p v-if="courseInfo.courseRoom">
							<i class="el-icon-school"> </i> 上课教室：{{
								courseInfo.courseRoom
							}}
						</p>
						<!--<p v-if="courseInfo.courseLimitGrade">
							<i class="el-icon-star-off"> </i> 适合年级：{{
								courseInfo.courseLimitGrade
							}}年级
						</p>-->
						<p v-if="courseInfo.courseContactPerson">
							<i class="el-icon-phone"> </i> 联系人：{{
								courseInfo.courseContactPerson
							}}
						</p>
						<p v-if="courseInfo.courseContactPhone">
							<i class="el-icon-mobile-phone"> </i> 联系电话：{{
								courseInfo.courseContactPhone
							}}
						</p>
						<p v-if="courseInfo.courseTeacher">
							<i class="el-icon-user"> </i> 授课教师：{{
								teacherInfo.teacherName
							}}
						</p>

            <p v-if="courseInfoTime.length > 0 &&courseInfoTime[0].timeType">
              <i class="el-icon-document-copy"> </i> 上课类型：{{
                courseInfoTime[0].timeType == 1 ? '专题课' : '体验课'
              }}
            </p>

						<div
							:style="{ lineHeight: '2em', width: '100%',display:'flex',alignItems:'center' }"
							v-if="
								courseInfoTime.length > 0 &&
									courseInfoTime[0].timeType == '2'
							"
						>
							<i class="el-icon-alarm-clock"> </i> 上课时间：
							<div
								v-for="(item, i) in courseInfoTime"
								:key="i"
							>
								<!--第{{ item.round }}轮次第{{ item.session }}课时:-->
								<span style="margin-right: 10px">{{item.date}}&nbsp;&nbsp;{{ item.startTime }}至{{ item.endTime }}</span>
							</div>
							<!-- <div>
								<span
									:style="{
										display: 'inline-block',
										width: '33%',
										textIndent: '2em',
									}"
									v-for="(item, i) in courseInfoTime"
									:key="i"
								>
									第{{ item.round }}轮次第{{
										item.session
									}}课时
									<span style="margin-right: 10px">{{
										item.date
									}}</span>
									<span>{{ item.startTime }} 至 </span>
									<span>{{ item.endTime }}</span>
								</span>
							</div> -->
						</div>
						<p
							v-if="
								courseInfoTime.length > 0 &&
									courseInfoTime[0].timeType != '2'
							"
						>
							<i class="el-icon-alarm-clock"> </i> 上课时间：{{
								courseInfoTime[0].time
							}}
						</p>
						<div>
							<i class="el-icon-location-information"> </i>
							上课地点：{{ courseSite.siteAddress }}
						</div>
            <div>
              <i class="el-icon-refrigerator"> </i>
				课程价格：￥0
            </div>
					</div>
					<div v-if="courseInfo" class="right">
						<!-- <button
              @click="handleAppointment(courseInfo)"
              v-if="courseInfo.courseStatus ==1&& courseInfo.status==1"
              class="btn"
            > -->
						<button
							@click="handleAppointment(courseInfo)"
							v-if="
								courseInfo.courseStatus == 1 &&
									courseInfo.status == 1
							"
							class="btn"
						>
							立即预约
						</button>
						<button
							@click="handleAppointment(courseInfo)"
							v-else-if="courseInfoTime[0].timeType == '2' && courseInfo.courseStatus == 8 "
							class="btn"
						>
						立即候补
						</button>
						<button
								@click="handleAppointment(courseInfo)"
								v-else-if="courseInfoTime[0].timeType == '1' && courseInfo.courseStatus == 8 "
								style="display: none"
						>
						</button>
						<button
							v-else
							class="btn"
							@click="$message.error('该课程当前暂不可预约')"
						>
							不可预约
						</button>
					</div>
				</div>
			</el-card>
		</div>
		<div class="container-title">
			<div class="title">课程介绍</div>
		</div>
		<div class="containar">
			<!-- <el-card class="intro"> -->
			<div
				class="richText ql-editor"
				v-html="courseInfo.courseDetail"
			></div>
<!--			<div
				class="hot-card"
				:class="activeStyle == true ? 'styleFixed' : 'styleOrigin'"
			>
				<el-card class="course-card">
					<div slot="header">
						<span>猜你喜欢</span>
					</div>
					<div class="course-list">
						<el-container
							v-for="(item, index) in courseHotList"
							:key="index"
							class="course-container"
							@click.native="selectCourse(item.id)"
						>
							<el-row :gutter="5" class="course-row">
								<el-col :span="12">
									<el-image
										:src="item.coursePicture"
										fit="cover"
									></el-image>
								</el-col>
								<el-col :span="12"
									><div class="courseTitle">
										{{ item.courseName }}
									</div>
								</el-col>
							</el-row>
						</el-container>
					</div>
				</el-card>
			</div>-->
			<!-- </el-card> -->
		</div>
	</div>
</template>

<script>
import bc from '@/assets/img/sceneInfoBc.jpg'
import { set, get } from '@/utils/local.js'
import { getCourseInfo_api, getCourseList_api } from '@/api/course'
import moment, { localeData } from 'moment'
export default {
	data() {
		return {
			activeStyle: false,
			islogin: false, // 是否登录
			biStyle: {
				// 导航栏下面的通栏背景图
				// height: "280px",
				backgroundImage: 'url(' + bc + ')',
			},
			courseInfo: '',
			courseHotList: null, //热门课程
			courseSite: '', //课程场景
			teacherInfo: '', //授课教师
			courseInfoTime: [], //上课时间
		}
	},
	methods: {
		scrollChange() {
			// 获取当前滚动条向下滚动的距离
			let scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop
			// 变量 windowHeight 是可视区的高度
			let windowHeight =
				document.documentElement.clientHeight ||
				document.body.clientHeight
			// 变量 scrollHeight 是滚动条的总高度
			let scrollHeight =
				document.documentElement.scrollHeight ||
				document.body.scrollHeight
			// 当页面滚动到高度550px处时
			if (scrollTop > 550) {
				this.activeStyle = true
			} else {
				this.activeStyle = false
			}
		},
		//猜你喜欢的查看课程
		selectCourse(id) {
			this.$router.push({
				path: '/course/courseInfo',
				query: { courseId: id },
			})
			this.getCourseInfo()
		},
		//获取热门课程
		async getHotCourse() {
			let { data, code } = await getCourseList_api({
				tenantId: this.$tenantId,
				size: 4,
				desc: 'course_heat',
				page: 1,
			})
			if ((code = 200)) {
				this.courseHotList = data.records.slice(0, 3)
			}
		},
		async getCourseInfo() {
			let { data, code } = await getCourseInfo_api({
				courseId: this.$route.query.courseId,
			})
			if (code === 200) {
				data.courseInfo.courseLabel = data.courseInfo.courseLabel.split(
					','
				)
				// data.courseDetail = decodeURIComponent(data.courseDetail);
				// 处理时间

				if (
					data.courseInfoTime.length > 0 &&
					data.courseInfoTime[0].timeType == 1
				) {
					data.courseInfoTime[0].time =
						moment(
							new Date(data.courseInfoTime[0].startDay)
						).format('YYYY-MM-DD') +
						' 至 ' +
						moment(new Date(data.courseInfoTime[0].endDay)).format(
							'YYYY-MM-DD'
						) +
						' 每周' +
						data.courseInfoTime[0].dayOfWeek +
						' ' +
						data.courseInfoTime[0].startTime +
						'-' +
						data.courseInfoTime[0].endTime

					this.courseInfoTime = data.courseInfoTime
				} else if (
					data.courseInfoTime.length > 0 &&
					data.courseInfoTime[0].timeType == 2
				) {
					// data.time = JSON.parse(data.recentRound).courseTimeList
					// data.time.forEach((item, i) => {
					// 	item.date = moment(item.date).format('yyyy-MM-DD')
					// })
					this.courseInfoTime = data.courseInfoTime
				}
				this.courseInfoTime = data.courseInfoTime
				this.courseSite = data.courseSite
				this.courseInfo = data.courseInfo
        console.log(this.courseInfoTime)
				// console.log(this.courseInfo)
				this.teacherInfo = data.teacherInfo
				this.$store.commit('updateCourseInfo', data.courseInfo)
			}
		},
		// 立即预约
		handleAppointment(item) {
			set('course', item)
			if (!this.$store.state.isLogin) {
				// 如果没登录
				this.$router.push(
					'/login?redirect=/course/courseAdd?courseId=' + item.id
				)
			} else {
				this.$router.push('/course/courseAdd?courseId=' + item.id)
			}
		},
	},
	created() {
		this.getCourseInfo()
		this.getHotCourse()
	},
	mounted() {
		// 监听页面滚动事件
		window.addEventListener('scroll', this.scrollChange)
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollChange)
	},
}
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
.root {
	background-color: #fff;
	// margin: 0;
	// padding: 0;
	.head {
		padding: 10px 13vw;
		.top {
			// margin: 10px 50px;
			padding: 10px 0 0 10px;
			h3 {
				font-size: 24px;
				font-weight: 700;
				color: @btn-color;
				margin-bottom: 10px;
				span {
					margin-left: 20px;
					padding: 2px 10px;
					font-size: 14px;
					border-radius: 15px;
					color: #fff;
					background-color: @title-color;
				}
			}
			.top-content {
				position: relative;
				.left {
					line-height: 2em;
					p {
						display: inline-block;
						min-width: 50%;
					}
				}
				.right {
					position: absolute;
					bottom: 0;
					right: 0;
					text-align: center;
					.btn {
						margin-bottom: 10px;
						background-color: @btn-color;
						height: 30px;
						width: 100px;
						border-radius: 15px;
					}
				}
			}
		}
	}
	.container-title {
		position: relative;
		height: 4em;
		border-bottom: 0.1px solid rgb(221, 221, 221);
		.title {
			position: absolute;
			left: 13vw;
			height: 100%;
			line-height: 4em;
			padding: 0 2vw;
			font-size: large;
			background-color: rgba(4, 123, 252, 1);
			color: #fff;
		}
	}
	.containar {
		width: 100vw;
		padding: 10px 13vw;
		display: grid;
		grid-template-columns: calc(100% - 275px) 275px;
		.richText,
		.ql-editor {
			border: 0.1px solid rgb(241, 239, 239);
			width: 100%;
			margin: 0 auto;
			/deep/.ql-align-center {
				text-align: center;
			}
			/deep/img {
				max-width: 100%;
			}
		}
		.hot-card {
			margin-left: 10px;
			// width: 100%;
			.course-list {
				.course-container {
					.course-row {
						border-bottom: 0.1px solid rgb(241, 241, 241);
						padding: 10px 0;
						cursor: pointer;
						&:hover .courseTitle {
							color: @title-color;
						}
						.courseTitle {
							text-align: center;
							margin-bottom: 20px;
							height: 30px;
							line-height: 1.5em;
							color: #666;
							font-size: 14px;
						}
					}
				}
			}
		}
		.styleFixed {
			position: fixed;
			top: 90px;
			width: 265px;
			right: calc(13vw - 5px);
		}
		.styleOrigin {
		}
	}
}
</style>
